@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@layer base {
    body {
        @apply font-[Open+Sans];
    }

    li {
        @apply px-4;
        @apply cursor-pointer;

    }

}